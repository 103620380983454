import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.scss';
import { LinkModel, dropdownLinkModel, SocialMediaModel } from 'models/common.data.model';
import { navbarLinks, airlegitAddress, socialMedia } from 'data/common.data';
import IconRenderer from 'modules/Shared/components/IconRenderer/IconRenderer';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

const Footer = (props: any) => {
    const tempLinks: LinkModel[] = [];
    const newUIURL: string = "https://app.airlegit.com";
    tempLinks.push(...navbarLinks);

    const [footerLinks, setFooterLinks] = useState<LinkModel[]>(tempLinks);
    const navigate = useNavigate();

    const gotoLink = (link: dropdownLinkModel | LinkModel) => {
        if (link.link && link.link.startsWith('http')) window.location.href = link.link;
        if (link.link && !link.link.startsWith('http')) {
            toggleOffFooterDropdown();
            // navigate(link.link);
            window.location.href = `${newUIURL}${link.link}`
        }
    }
    const gotoHome = () => {
        // navigate('/');
        window.location.href = 'https://app.airlegit.com/';
    }

    const toggleFooterDropdown = (linkId: string) => {
        const tempFooterLink: LinkModel[] = [...footerLinks];
        tempFooterLink.forEach((link: LinkModel) => link.id === linkId ? link.displayDropdown = !link.displayDropdown : link.displayDropdown = false);
        setFooterLinks(tempFooterLink);
    }
    const toggleOffFooterDropdown = () => {
        const tempFooterLink: LinkModel[] = [...footerLinks];
        tempFooterLink.forEach((link: LinkModel) => link.displayDropdown = false);
        setFooterLinks(tempFooterLink);
    }
    const dropdownItems = (link: LinkModel) => {
        return (
            <>
                {link.dropdownLink?.map((ddLink: dropdownLinkModel) => (
                    <div key={'footer-dd-' + ddLink.id} className='dropdown-link' onClick={() => gotoLink(ddLink)}>
                        {ddLink.name}
                    </div>
                ))}
            </>
        );
    }
    const footerLinkItems = footerLinks.map((link: LinkModel) => {
        if (!link.id.includes('header')) return (
            <div key={'footer-' + link.id}>
                <div key={'footer-' + link.id} className='primary-link' onClick={() => {
                    toggleFooterDropdown(link.id);
                    gotoLink(link);
                }}>
                    {link.name}
                    {!!link.dropdownLink.length &&
                        <span className='ms-2'>
                            {!link.displayDropdown && <FaChevronDown />}
                            {!!link.displayDropdown && <FaChevronUp />}
                        </span>
                    }
                </div>
                {!!link.dropdownLink.length && link.displayDropdown &&
                    <div className='dropdown-wrapper'>
                        {dropdownItems(link)}
                    </div>
                }
            </div>
        );
        else return null;
    });
    const addressData = () => {
        return (
            <>
                <div className='address-details'>
                    {airlegitAddress.name}
                </div>
                <div className='address-details'>
                    {airlegitAddress.line1}
                </div>
                <div className='address-details'>
                    {airlegitAddress.line2}
                </div>
                <div className='address-details'>
                    {airlegitAddress.city}, {airlegitAddress.stateCode} - {airlegitAddress.zipcode}
                </div>
            </>
        );
    }
    const socialMediaItems = socialMedia.map((media: SocialMediaModel) => {
        return (
            <a key={media.name} href={media.url} target='_blank' rel='noopener noreferrer' title={media.name}>
                <IconRenderer iconName={media.logo} />
            </a>
        )
    });

    return (
        <div className='footer-container container-side-padding container-content-padding'>
            <div className='container-small-width'>
                <div className='container no-padding'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='logo'>
                                <img src='/images/logos/airlegit-logo-1.png' alt='AirLegit' onClick={() => gotoHome()} />
                            </div>
                            <div className='all-rights-reserved'>
                                &#169; {new Date().getFullYear()} All Rights Reserved
                            </div>
                        </div>
                        <div className='col-md-3 small-screen-padding-top'>
                            <div className='links'>
                                {footerLinkItems}
                            </div>
                        </div>
                        <div className='col-md-3 small-screen-padding-top'>
                            <div className='address'>
                                {addressData()}
                            </div>
                        </div>
                        <div className='col-md-3 small-screen-padding-top'>
                            <div className='social-media'>
                                {socialMediaItems}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;