// import React, { useState } from 'react';
import './Product.scss';
import { CardModel } from 'models/common.data.model';

export const products: CardModel[] = [
    { key: 'product-1', logo: '/images/icons/ALEG-ID-FG48_IconWhite.svg', header: 'FLIGHTGUARD48', content: 'Critical service for those that need to get to their destination.', link: '/product/fg48', class: '' },
    { key: 'product-2', logo: '/images/icons/ALEG-ID-ICON-Insurance_White.svg', header: 'TRAVEL INSURANCE', content: 'Protect your flights, accommodations, health, belongings and overall trip investment, in case the unexpected happens', link: `/product/insurance`, class: 'small-screen-padding-top' },
    { key: 'product-3', logo: '/images/icons/ALEG-ID-ICON-Watcher_White.svg', header: 'FLIGHT WATCHER', content: 'Real-time delay and cancellation tracking with reliability prediction', link: `/product/flight-watcher`, class: 'small-screen-padding-top' },
    { key: 'product-4', logo: '/images/icons/ALEG-ID-ICON-Checkin_White.svg', header: 'AUTO CHECK-IN', content: 'Automated check-in and Boarding pass delivery over 200+ airlines and 6000+ airports globally', link: `/product/auto-checkin`, class: 'small-screen-padding-top' }
];

const Product = (props: any) => {
    const newUIURL: string = "https://app.airlegit.com";
    const tempCards: CardModel[] = [];
    tempCards.push(...products);
    const gotoLink = (link: string) => {
        // window.location.href = link;
        window.location.href = `${newUIURL}${link}`;
    }
    const cardItems = tempCards.map((card: CardModel) => {
        let classData: string = 'col-md-3';
        if (card.class) classData = `${classData} ${card.class}`;
        return (
            <div key={card.key} className={classData}>
                <div className='data-card-wrapper'>
                    <div className='logo-wrapper'>
                        <img src={card.logo} alt={card.header} />
                    </div>
                    <div className='content-header text-l2'>
                        {card.header}
                    </div>
                    <div className='content-data'>
                        {card.content}
                    </div>
                    <div className='link-btn'>
                        <button onClick={() => gotoLink(card.link)} className='btn-primary'>
                            MORE &#8594;
                        </button>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <>
            <div className='container-fluid no-padding'>
                <div className='row no-margin product card-container'>
                    {cardItems}
                </div>
            </div>
        </>
    )
};

export default Product;