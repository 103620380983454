import React from 'react';
import { IconType } from 'react-icons';
import { FaCircle, FaTimes, FaPlus, FaBars, FaLinkedinIn, FaWhatsapp, FaYoutube, FaPhone, FaEnvelope, FaPlane, FaPlaneDeparture, FaPlaneArrival } from 'react-icons/fa';

const IconRenderer = ({ iconName }: any) => {
    let IconComponent: IconType;
    switch (iconName) {
        case 'cross':
        case 'times':
            IconComponent = FaTimes;
            break;
        case 'plus':
            IconComponent = FaPlus;
            break;
        case 'bars':
        case 'burger':
        case 'menu':
            IconComponent = FaBars;
            break;
        case 'linkedin':
            IconComponent = FaLinkedinIn;
            break;
        case 'youtube':
            IconComponent = FaYoutube;
            break;
        case 'whatsapp':
            IconComponent = FaWhatsapp;
            break;
        case 'phone':
            IconComponent = FaPhone;
            break;
        case 'envelope':
        case 'mail':
        case 'email':
            IconComponent = FaEnvelope;
            break;
        case 'plane':
            IconComponent = FaPlane;
            break;
        case 'plane-departure':
            IconComponent = FaPlaneDeparture;
            break;
        case 'plane-arrival':
            IconComponent = FaPlaneArrival;
            break;
        default:
            IconComponent = FaCircle;
    }
    return <IconComponent />;
};

export default IconRenderer;