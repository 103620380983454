// import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Industry.scss';

interface IndustryCardModel {
    key: string;
    logo: string;
    header: string;
    content: string;
    classname: string;
    link: string;
}

const industries: IndustryCardModel[] = [
    { key: 'industry-1', logo: '/images/icons/ALEG-Image-Icons-Home_Industries_Airline.svg', header: 'AIRLINES', content: 'Value-added services for rebooking and automated customer support.', classname: '', link: '/industries/airlines' },
    { key: 'industry-2', logo: '/images/icons/ALEG-Image-Icons-Home_Industries_OTA.svg', header: 'ONLINE TRAVEL AGENCIES', content: 'Additional revenue and service differentiation with flight disruption management.', classname: 'small-screen-padding-top', link: '/industries/ota' },
    { key: 'industry-3', logo: '/images/icons/ALEG-Image-Icons-Home_Industries_Tech.svg', header: 'TRAVEL TECHNOLOGY INNOVATORS', content: 'Solutions to expand or complete service suite for expanded reach and relevance.', classname: 'small-screen-padding-top large-screen-padding-top', link: '/industries/travel-tech' },
    { key: 'industry-4', logo: '/images/icons/ALEG-Image-Icons-Home_Industries_TMC.svg', header: 'TRAVEL MANAGEMENT COMPANIES', content: 'Proactive notification and automated rebooking for business travelers.', classname: 'small-screen-padding-top large-screen-padding-top', link: '/industries/tmc' }
];


const Industry = (props: any) => {
    const navigate = useNavigate();
    const newUIURL: string = "https://app.airlegit.com";
    const gotoLink = (link: string) => {
        // navigate(link);
        window.location.href = `${newUIURL}${link}`;
    };

    const cardItems = industries.map((card: IndustryCardModel) => {
        let classData = 'col-md-6 industry-feature-card';
        if (card.classname) classData = `${classData} ${card.classname}`;
        return (
            <div key={card.key} className={classData}>
                <div className='industry-card-wrapper' onClick={() => gotoLink(card.link)}>
                    <div className='logo-wrapper'>
                        <img src={card.logo} alt={card.header} />
                    </div>
                    <div className='content-header text-l2'>
                        {card.header}
                    </div>
                    <div className='content-data'>
                        {card.content}
                    </div>
                </div>
            </div>
        );
    });

    return (
        <>
            <div className='container-fluid no-padding'>
                <div className='row no-margin industry-container'>
                    <div className='col-md-4 no-padding'>
                        <div className='bordered-img-wrapper'>
                            <img src='/images/others/ALEG-Image-Body_Home.jpg' alt='industry' onClick={() => gotoLink('/industries')} />
                        </div>
                    </div>
                    <div className='col-md-6 offset-md-2 no-padding'>
                        <div className='row no-margin'>
                            {cardItems}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Industry;