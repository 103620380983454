import React, { useState, useEffect, forwardRef, useImperativeHandle, ForwardedRef, useRef } from 'react';
import { UtilAPI } from 'services/util.service';
import { isEmailValid } from 'data/common.data';
import 'modules/MainModule/components/FlightChecker/FlightChecker.scss';
import './Contact.scss';
import { FaTimes } from 'react-icons/fa';
import { ContactModel, ResponseModel } from 'models/api.data.model';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const Contact = forwardRef((props: any, contactRef: ForwardedRef<unknown>) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isContactVisible, setIsContactVisible] = useState<boolean>(false);
    const [contactWidth, setContactWidth] = useState<string>('-400px');
    // const setInfo = (url: string) => {
    //     let info: string = url;
    //     if (['', '/'].includes(info)) info = '/homepage';
    //     return info;
    // }
    const defaultFormData: ContactModel = { name: '', mail: '', message: '', info: props.pageUrl };
    const [contactData, setContactData] = useState<ContactModel>({ ...defaultFormData });
    const formRef = useRef<any>(null);
    const newUIURL: string = "https://app.airlegit.com";
    const handleResize = () => {
        // 33px is button height, 1st 15px is vertical scroll bar width, 2nd 15px is normal padding-right value
        if (window.innerWidth <= 575) setContactWidth((window.innerWidth - 33 - 15 - 15).toString() + 'px');
        else setContactWidth('400px');
    };
    useEffect(() => {
        handleResize();
        // setInfo();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });
    useImperativeHandle(contactRef, () => {
        return {
            openContact: () => { toggleContact(true); }
        };
    });

    const toggleContact = (displayStatus: boolean) => {
        setIsContactVisible(displayStatus);
    }
    const contactStyle = {
        width: contactWidth,
        left: isContactVisible ? '0px' : `-${contactWidth}`,
    };

    const handleContactChange = (e: any) => {
        const { name, value } = e.target;
        setContactData({ ...contactData, [name]: value });
    };
    const handleContactSubmit = (e: any) => {
        e.preventDefault();

        if (!contactData.name.trim() || !contactData.mail.trim() || !contactData.message.trim()) {
            toast.error('Please fill out all mandatory fields.');
            return;
        }
        if (!isEmailValid(contactData.mail)) {
            toast.error('Please enter a valid email address.');
            return;
        }

        setIsLoading(true);
        UtilAPI.submitContact(contactData)
            .then((res: ResponseModel) => {
                setIsLoading(false);
                if (res.status) {
                    resetContact();
                    toggleContact(false);
                    toast.success('Contact details submitted successfully.');
                } else {
                    console.warn(res);
                    toast.error('Failed to submit contact details. Response status is false.');
                }
            })
            .catch((err: any) => {
                setIsLoading(false);
                console.error(err);
                toast.error('Failed to submit contact details. Error.');
            });
    }
    const resetContact = () => {
        setContactData({ ...defaultFormData });
        formRef.current.reset();
    }
    const navigate = useNavigate();
    const gotoContactUs = () => {
        // navigate('/contact-us');
        window.location.href = `${newUIURL}/contact-us`
    }
    return (
        <>
            <div className='contact-container'>
                <button id='toggle-contact' onClick={gotoContactUs}>
                    CONTACT US
                </button>
            </div>
        </>
    );
});

export default Contact;