import { _get, _post } from './api.service';
import { ContactModel,IContactUsModel } from 'models/api.data.model';

const apiURL = process.env.REACT_APP_API_ENDPOINT;
const frapiURL = process.env.REACT_APP_FRAPI_ENDPOINT;

export const UtilAPI = {
    submitContact: async (reqPayload: ContactModel) => {
        return await _post(`${apiURL}/utils/contact`, reqPayload);
    },
    searchAirlines: async (data: string) => {
        return await _get(`${frapiURL}/airlines/search?q=${data}`);
    },
    submitContactUs: async (reqPayload: IContactUsModel) => {
        return await _post(`${apiURL}/utils/contact`, reqPayload);
    },
   
}