import React, { useState, useRef } from 'react';
import { UtilAPI } from 'services/util.service';
import './FlightChecker.scss';
import { AirlineSearchModel } from 'models/common.data.model';
import { ResponseModel, FlightCheckerModel } from 'models/api.data.model';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const FlightChecker = (props: any) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [airlineList, setAirlineList] = useState<AirlineSearchModel[]>([]);
    const [flightCheckerData, setFlightCheckerData] = useState<FlightCheckerModel>({ airlineCode: '', flightNumber: NaN });
    const typeaheadRef = useRef<any>(null);

    const onSearchAirlines = (query: string) => {
        UtilAPI.searchAirlines(query)
            .then((res: ResponseModel) => {
                if (res.status) {
                    setAirlineList(res.payload);
                } else {
                    console.warn(res);
                    toast.error('Failed to search airlines. Response status is false.');
                }
            })
            .catch((err: any) => {
                console.error(err);
                toast.error('Failed to search airlines. Error.');
            });
    }
    const onChangeAirline = (selected: any[]) => {
        setFlightCheckerData({ ...flightCheckerData, airlineCode: selected[0]?.code });
    }
    const onBlurTypeahead = () => {
        if (!flightCheckerData || !flightCheckerData.airlineCode) {
            setFlightCheckerData({ ...flightCheckerData, airlineCode: '' });
            typeaheadRef.current.clear();
        }
    }
    const handleFlightCheckerChange = (e: any) => {
        const { name, value } = e.target;
        setFlightCheckerData({ ...flightCheckerData, [name]: value });
    };

    const gotoFightStatus = () => {
        if (!flightCheckerData.airlineCode || isNaN(flightCheckerData.flightNumber)) {
            toast.error('Please enter airline and flight number');
            return;
        }

        setIsLoading(true);
        window.location.href = `${process.env.REACT_APP_DOMAIN}/app/flightstatus/${flightCheckerData.airlineCode}/${flightCheckerData.flightNumber}`;
        setIsLoading(false);
    }

    return (
        <>
            <ToastContainer theme='colored' />
            <div className='container-fluid no-padding'>
                <div className='row no-margin flight-checker-wrapper'>
                    <div className='col-md-5 no-padding'>
                        <div className='content-wrapper'>
                            <div className='logo'>
                                <img src='/images/icons/ALEG-ID-ICON-FlightChecker_Black.png' alt='Flight Checker' />
                            </div>
                            <div className='text-content text-med'>
                                AirLegit's Platform checks flight status, rates online performance and gives you the information you need to make more reliable travel plans.
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2 no-padding'>
                        <div className='separator-wrapper'>
                            <div className='separator'></div>
                        </div>
                    </div>
                    <div className='col-md-5 no-padding'>
                        {/* onSubmit={gotoFightStatus} */}
                        <form id='flight-checker-form' className='flight-checker-form' noValidate>
                            <div className='cust-form-group'>
                                <label>Airline (autocomplete)<i className='text-error'>*</i></label>
                                {/* <input type='text' id='fcs-airline' /> */}
                                <AsyncTypeahead
                                    ref={typeaheadRef}
                                    filterBy={() => true}
                                    id='fcs-airline'
                                    className='typeahead'
                                    isLoading={isLoading}
                                    labelKey='name'
                                    minLength={2}
                                    onSearch={onSearchAirlines}
                                    onChange={onChangeAirline}
                                    onBlur={() => onBlurTypeahead()}
                                    options={airlineList}
                                    placeholder=''
                                    renderMenuItemChildren={(airline: any) => (
                                        <>
                                            <span>{airline.name}</span>
                                        </>
                                    )}
                                />
                            </div>
                            <div className='cust-form-group'>
                                <label htmlFor='fcs-flight-number'>Flight Number<i className='text-error'>*</i></label>
                                <input type='number' id='fcs-flight-number' name='flightNumber' onChange={handleFlightCheckerChange} required />
                            </div>
                            {/* <div className='cust-form-group'>
                                <label htmlFor='fcs-email'>Your Email (for the report)<i className='text-error'>*</i></label>
                                <input type='email' id='fcs-email' name='email' required />
                            </div> */}
                            <div className='re-captcha-info'>
                                This site is protected by reCAPTCHA. Google's <a href='https://policies.google.com/privacy' className='link'>Privacy Policy</a> and <a href='https://policies.google.com/terms' className='link'>Terms of Service</a> apply.
                            </div>
                            <div className='cust-form-group button-wrapper'>
                                {/* <button type='submit' id='fcs-get-status'>
                                    Get the Status & History
                                </button> */}
                                <button type='button' id='fcs-get-status' onClick={gotoFightStatus} >
                                    Get the Status & History
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FlightChecker;