import React from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './modules/MainModule/pages/Home/Home';
import About from './modules/MainModule/pages/About/About';
import Privacy from './modules/MainModule/pages/Privacy/Privacy';
import Security from './modules/MainModule/pages/Security/Security';
import Terms from './modules/MainModule/pages/Terms/Terms';
import IndustryBase from './modules/Industry/pages/IndustryBase/IndustryBase';
import Airlines from './modules/Industry/pages/Airlines/Airlines';
import OTA from './modules/Industry/pages/OTA/OTA';
import TMC from './modules/Industry/pages/TMC/TMC';
import TravelTech from './modules/Industry/pages/TravelTech/TravelTech';
import Checkin from './modules/Product/pages/Checkin/Checkin';
import FlightWatcher from './modules/Product/pages/FlightWatcher/FlightWatcher';
import Insurance from './modules/Product/pages/Insurance/Insurance';
import FG48 from './modules/Product/pages/FG48/FG48';
import Page404 from './modules/MainModule/pages/Page404/Page404';
import ContactUs from 'modules/MainModule/pages/ContactUs/ContactUs';
import SubscriptionAgreement from 'modules/MainModule/pages/SubscriptionAgreement/SubscriptionAgreement';
import PartnerTP from 'modules/Partners/pages/PartnerTP/PartnerTP';
import Partners from 'modules/Partners/pages/Partners/Partners';
const App = () => {
  return (
    <BrowserRouter>
      <div className='main-container full-height full-width min-full-screen-height'>
        <Routes>
          <Route path='/' element={<Home />} />
          {/*<Route path='/about' element={<About />} />
          <Route path='/industries' element={<IndustryBase />} />
          <Route path='/industries/ota' element={<OTA />} />
          <Route path='/industries/tmc' element={<TMC />} />
          <Route path='/industries/travel-tech' element={<TravelTech />} />
          <Route path='/industries/airlines' element={<Airlines />} />
          <Route path='/product/auto-checkin' element={<Checkin />} />
          <Route path='/product/flight-watcher' element={<FlightWatcher />} />
          <Route path='/product/insurance' element={<Insurance />} />
          <Route path='/product/fg48' element={<FG48 />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/security' element={<Security />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='terms/subscriptions' element={<SubscriptionAgreement />} />
          <Route path='/contact-us' element={<ContactUs />} /> */}
          {/* <Route path='/partners'>
            <Route path='' element={<Partners />} />
            <Route path='tunesprotect' element={<PartnerTP />} />
            <Route path='*' element={<Page404 />} />
          </Route> */}
          <Route path='*' element={<Page404 />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
