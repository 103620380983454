import React, { useRef, useEffect } from 'react';
import './Home.scss';
import Header from 'modules/Shared/components/Header/Header';
import Footer from 'modules/Shared/components/Footer/Footer';
import FlightChecker from 'modules/MainModule/components/FlightChecker/FlightChecker';
import Product from 'modules/MainModule/components/Product/Product';
import Industry from 'modules/MainModule/components/Industry/Industry';
import Testimonial from 'modules/MainModule/components/Testimonial/Testimonial';
import Contact from 'modules/MainModule/components/Contact/Contact';
import { colorBackgroundBrightness } from 'data/common.data';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';

const Home = (props: any) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    const headerBg: string = '/images/backgrounds/ALEG-Image-Header_Home.jpg';
    const styleData = { backgroundImage: `linear-gradient(${colorBackgroundBrightness}, ${colorBackgroundBrightness}), url(${headerBg})` };

    const headerBgTravelDisruption: string = '/images/backgrounds/ALEG-Image-Home-VideoWatcher.jpg';
    const styleDataTravelDisruption = { backgroundImage: `linear-gradient(${colorBackgroundBrightness}, ${colorBackgroundBrightness}), url(${headerBgTravelDisruption})` };
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>FlightWatcher, Automated Check-In, FlightGuard48 and Travel Insurance</title>
                    <meta name='description' content='Get insights and actionable recommendations with Flight Watcher when flight delays, flight cancellations happen.' />
                </Helmet>
            </HelmetProvider>
            <Header/>
            <div className='page-min-height home-data-container'>
                <div className='header-container home-header-container container-side-padding container-content-padding' style={styleData}>
                    <div className='container-small-width'>
                        <div className='container no-padding'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h1>
                                        Air Travel<br />
                                        Tools
                                    </h1>
                                    <p className=''>
                                        AirLegit&#169; enables companies to improve their travelers' end-to-end journeys.
                                    </p>
                                    <p className=''>
                                        We work with airlines, online travel agencies, travel management companies and travel technology innovators to improve their customer experience and generate additional ancillary revenue.
                                    </p>
                                </div>
                                <div className='col-md-6'>
                                    <div className='logo-wrapper'>
                                        <img src='/images/others/ALEG-Image-Header_TabletPhone.png' alt='air travel tools' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mission-container container-side-padding container-content-padding'>
                    <div className='container-small-width'>
                        <div className='container no-padding'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <p className='no-margin text-med'>
                                        AirLegit&#169; alleviates persistent travel friction by anticipating and remedying flight disruptions with an innovative combination of flight performance data analytics, proactive personalized traveler support and ready-made travel disruption automation tools.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='travel-disruption-container container-side-padding container-content-padding' style={styleDataTravelDisruption}>
                    <div className='container-small-width'>
                        <div className='container no-padding'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h2>
                                        Travel Disruption Automation
                                    </h2>
                                    <div className='media-wrapper'>
                                        <div className='media-content'>
                                            <iframe title={'AirLegit\'s Concierge for Air Traveler'} src='https://www.youtube.com/embed/7RwoeWnDasM'>
                                            </iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='product-container container-side-padding container-content-padding'>
                    <div className='container no-padding'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Product />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flight-checker-container container-side-padding container-content-padding'>
                    <div className='container-small-width'>
                        <div className='container no-padding'>
                            <div className='row'>
                                <div className='col-12 no-padding'>
                                    <FlightChecker />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='industries-container container-side-padding container-content-padding'>
                    <div className='container no-padding'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Industry />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='testimonial-container container-side-padding container-content-padding'>
                    <div className='container-small-width'>
                        <div className='container no-padding'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Testimonial />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Contact />
            <Footer/>
        </>
    );
};

export default Home;