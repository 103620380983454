import { LinkModel, AddressModel, SocialMediaModel } from 'models/common.data.model';

export const uiURL = process.env.REACT_APP_UI_DOMAIN;
export const newUIURL = process.env.NEW_UI_DOMAIN;

export const insuranceSampleDemo: string = `${uiURL}/app/insurance-terms`;
export const flightWatcherLink: string = `${newUIURL}/product/flight-watcher`;
export const subscriptionAgreementLink: string = `${newUIURL}/terms/subscriptions`;
export const insuranceLink: string = `${newUIURL}/product/insurance`;
export const dashboardRegistrationLink: string = `${uiURL}/app/dashboard/register`;
const DASHBOARD_V1_LINK: string = `${uiURL}/app/dashboard/login`;
export const navbarLinks: LinkModel[] = [
    {
        id: '1', name: 'TOOLS', link: '', displayDropdown: false, dropdownLink: [
            { id: '1-1', name: 'CHECK FLIGHT STATUS', link: `${uiURL}/app/flightstatus` },
            { id: '1-2', name: 'FLIGHT WATCHER', link: `/product/flight-watcher` },
            { id: '1-3', name: 'AUTOMATED CHECK-IN', link: `/product/auto-checkin` },
            { id: '1-4', name: 'FLIGHTGUARD48', link: `https://flightguard48.com` },
            { id: '1-5', name: 'TRAVEL INSURANCE', link: `/product/insurance` }
        ]
    },
    {
        id: '2', name: 'INDUSTRIES', displayDropdown: false, link: '', dropdownLink: [
            { id: '2-1', name: 'ONLINE TRAVEL AGENCIES', link: '/industries/ota' },
            { id: '2-2', name: 'TRAVEL MANAGEMENT COMPANIES', link: '/industries/tmc' },
            { id: '2-3', name: 'TRAVEL TECHNOLOGY INNOVATORS', link: '/industries/travel-tech' },
            { id: '2-4', name: 'AIRLINES', link: '/industries/airlines' }
        ]
    },
    { id: '3', name: 'FLIGHTCHECKER', displayDropdown: true, link: `${uiURL}/app/flightstatus`, dropdownLink: [] },
    { id: 'header-4', name: 'NEWS', displayDropdown: false, link: '/about/#news', dropdownLink: [] },
    { id: 'header-11', name: 'PARTNERS', displayDropdown: false, link: '/partners', dropdownLink: [] },
    { id: 'contact-5', name: 'CONTACT US', displayDropdown: false, link: '/contact-us', dropdownLink: [] },
    // { id: 'header-6', name: 'CLIENT LOG IN', displayDropdown: false, link: `${uiURL}/app/dashboard/login`, dropdownLink: [] },
    {
        id: 'header-6', name: 'CLIENT LOG IN', displayDropdown: false, link: '', dropdownLink: [
            { id: '6-1', name: 'DASHBOARD V1', link: DASHBOARD_V1_LINK },
            { id: '6-2', name: 'DASHBOARD V2', link: `/login` },
        ]
    },
    { id: 'footer-7', name: 'PRIVACY', displayDropdown: false, link: '/privacy', dropdownLink: [] },
    { id: 'footer-8', name: 'SECURITY', displayDropdown: false, link: '/security', dropdownLink: [] },
    { id: 'footer-9', name: 'TERMS', displayDropdown: false, link: '/terms', dropdownLink: [] },
    { id: 'footer-10', name: 'ABOUT AIRLEGIT', displayDropdown: false, link: '/about', dropdownLink: [] },
];

export const airlegitAddress: AddressModel = {
    name: 'AirLegit',
    line1: '1177 Avenue of the Americas',
    line2: '5th Floor',
    city: 'New York',
    state: 'New York',
    stateCode: 'NY',
    zipcode: '10036',
    country: 'United States',
    countryCode: 'USA'
};

export const socialMedia: SocialMediaModel[] = [
    { name: 'LinkedIn', url: 'https://www.linkedin.com/company/airlegit', logo: 'linkedin' },
    { name: 'WhatsApp', url: 'https://wa.me/12513130360', logo: 'whatsapp' },
    { name: 'YouTube', url: 'https://www.youtube.com/channel/UCI7FY7flMC3iXg_BM7vvRIA', logo: 'youtube' },
    { name: 'support@airlegit.com', url: 'mailto:support@airlegit.com', logo: 'email' }
];

const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const isEmailValid = (email: string): boolean => {
    return emailRegex.test(email);
}

export const colorBackgroundBrightness: string = '#00000088';