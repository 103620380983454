// import React, { useState } from 'react';
import './Testimonial.scss';
import { useNavigate } from 'react-router-dom';
import { CardModel } from 'models/common.data.model';
import { FaQuoteLeft } from 'react-icons/fa';

export const testimonials: CardModel[] = [
    { key: 'testimonial-1', logo: '/images/logos/ALEG-Image-Testimonial-battleface.svg', header: 'Battleface ', content: 'We are pleased with the cutting edge automated customer service solution from AirLegit allowing interactions via Phone and SMS.', link: '/industries/travel-tech', class: '' },
    { key: 'testimonial-4', logo: '/images/logos/ALEG-Image-Testimonial-SAMGI.png', header: 'SAMGI', content: 'The experience allows for a seamless travel experience across our footprints. HUGE thanks to AirLegit for building an automated process that solution  be even possible', link: '/industries/tmc', class: 'small-screen-padding-top' },
    { key: 'testimonial-2', logo: '/images/logos/ALEG-Image-Testimonial-ITIJ.png', header: 'ITIJ ', content: 'We found AirLegit\'s real-time monitoring solutions are very helpful to customers when a flight is delayed or canceled.', link: '/industries/ota', class: 'small-screen-padding-top' }
    // { key: 'testimonial-3', logo: '/images/logos/ALEG-Image-Testimonial-Kiwi.png', header: 'Kiwi.COM', content: 'We are able to process many more online check-ins automatically for our customers and make their journey easier.', link: '/industries', class: 'small-screen-padding-top' },
];

const Testimonial = (props: any) => {
    const newUIURL: string = "https://app.airlegit.com";
    const tempCards: CardModel[] = [];
    tempCards.push(...testimonials);
    const navigate = useNavigate();
    const gotoLink = (link: string) => {
        // navigate(link);
        window.location.href = `${newUIURL}${link}`;
    }
    const cardItems = tempCards.map((card: CardModel) => {
        let classData: string = 'col-md-4';
        if (card.class) classData = `${classData} ${card.class}`;
        return (
            <div key={card.key} className={classData}>
                <div className='data-card-wrapper'>
                    <div className='logo-wrapper'>
                        <img src={card.logo} alt={card.header} />
                    </div>
                    <div className='content-header'>
                        <FaQuoteLeft />
                    </div>
                    <div className='content-data'>
                        {card.content}
                    </div>
                    <div className='link-btn'>
                        <div className='separator'></div>
                        <button onClick={() => gotoLink(card.link)} className='btn-primary'>
                            Learn More
                        </button>
                    </div>
                </div>
            </div>
        );
    });
    return (
        <>
            <div className='container-fluid no-padding'>
                <div className='row no-margin testimonial card-container'>
                    {cardItems}
                </div>
            </div>
        </>
    )
};

export default Testimonial;