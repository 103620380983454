import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Page404.scss';
import Header from 'modules/Shared/components/Header/Header';
import Footer from 'modules/Shared/components/Footer/Footer';
import FlightChecker from 'modules/MainModule/components/FlightChecker/FlightChecker';
import Contact from 'modules/MainModule/components/Contact/Contact';
import { colorBackgroundBrightness } from 'data/common.data';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Page404 = (props: any) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    const navigate = useNavigate();
    const gotoHome = () => {
        // navigate('/');
        window.location.href = 'https://app.airlegit.com/';
    }
    const headerBg: string = '/images/backgrounds/ALEG-Image-TravelManagement_Header.jpg';
    const styleData = { backgroundImage: `linear-gradient(${colorBackgroundBrightness}, ${colorBackgroundBrightness}), url(${headerBg})` };
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>About AirLegit</title>
                    <meta name='description' content='We work with airlines, OTAs, travel mgmt companies, and travel technology innovators to improve their CX and generate additional ancillary revenue.' />
                </Helmet>
            </HelmetProvider>
            <Header />
            <div className='page-min-height page-404-data-container'>
                <div className='header-container container-side-padding container-content-padding' style={styleData}>
                    <div className='container-small-width'>
                        <div className='container no-padding'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h1>
                                        404
                                    </h1>
                                    <p className='not-found-text text-l2'>
                                        Oops! The page you requested could not be found.
                                    </p>
                                    <div className='btn-wrapper'>
                                        <button className='btn-primary' onClick={gotoHome}>
                                            BACK TO HOME
                                        </button>
                                    </div>
                                </div>
                                <div className='col-md-6 small-screen-padding-top'>
                                    <div className='logo-wrapper' style={{ maxWidth: '300px' }}>
                                        <img src='/images/others/ALEG-Image-Header_Page404.svg' alt='air travel tools' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flight-checker-container container-side-padding container-content-padding'>
                    <div className='container-small-width'>
                        <div className='container no-padding'>
                            <div className='row'>
                                <div className='col-12 no-padding'>
                                    <FlightChecker />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Contact />
            <Footer />
        </>
    );
};

export default Page404;