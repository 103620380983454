import axios from 'axios';

export const _get = async (url: string) => {
    try {
        const response = await axios.get(url);
        return response?.data;
    } catch (error) {
        throw error;
    }
};
export const _post = async (url: string, data: any) => {
    try {
        const response = await axios.post(url, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
};