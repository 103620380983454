import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import './Header.scss';
import { LinkModel, dropdownLinkModel, ILogoData } from 'models/common.data.model';
import { navbarLinks } from 'data/common.data';
import { FaBars, FaChevronDown } from 'react-icons/fa';
import { link } from 'fs';

interface IHeaderProps {
    partnerLogo?: ILogoData;
}


const Header = ({ partnerLogo, ...props }: IHeaderProps) => {
    const newUIURL: string = "https://app.airlegit.com";

    const [headerLinks, setHeaderLinks] = useState<LinkModel[]>(JSON.parse(JSON.stringify(navbarLinks)));
    const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);
    const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleResize = () => {
        setInnerWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const gotoLink = (link: dropdownLinkModel | LinkModel) => {
        if (link.link && link.link.startsWith('http')) window.location.href = link.link;
        if (link.link && !link.link.startsWith('http')) {
            toggleOffHeaderDropdown();
            // navigate(link.link);
            window.location.href = `${newUIURL}${link.link}`
        };
    }
    const gotoHome = () => {
        // navigate('/');
        window.location.href = 'https://app.airlegit.com/';
    }
    const gotoPartnerLink = (link: string) => {
        window.open(link, '_blank')
    }
    const toggleOffHeaderDropdown = () => {
        const tempHeadererLink: LinkModel[] = [...headerLinks];
        tempHeadererLink.forEach((link: LinkModel) => link.displayDropdown = false);
        setHeaderLinks(tempHeadererLink);
    }
    const bootstrapDropdownLinks = (link: LinkModel) => {
        return (<>
            <Dropdown>
                <Dropdown.Toggle id={`dropdown-${link.id}`}>
                    {link.name} <FaChevronDown />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {link.dropdownLink?.map((ddLink: dropdownLinkModel) => (
                        <Dropdown.Item key={'header-dd-' + ddLink.id} className='dropdown-link' onClick={() => gotoLink(ddLink)}>
                            {ddLink.name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>);
    }
    const headerLinkItems = headerLinks
        .filter((link: LinkModel) => {
            if (partnerLogo && link.id === 'header-6') return false;
            return true;
        })
        .map((link: LinkModel) => {
            if (link.dropdownLink.length) {
                let wrapperClass = 'bootstrap-dropdown-link-wrapper';
                if (link.id === 'header-6') wrapperClass += ' last-link';
                return (
                    <div key={'header-' + link.id} className={wrapperClass}>
                        {bootstrapDropdownLinks(link)}
                    </div>
                );
            } else if (!link.id.startsWith('footer') && !link.dropdownLink.length) {
                return (
                    <div key={'header-' + link.id} className='link-wrapper'>
                        <div className='primary-link' onClick={() => { gotoLink(link); }}>
                            {link.name}
                        </div>
                    </div>
                );
            } else return null;
        });

    const toggleShowLink = () => {
        const displayStatus: boolean = isDropdownVisible;
        setIsDropdownVisible(!displayStatus);
    }
    const renderNavbarLinks = () => {
        if (innerWidth > 767)
            return (
                <div className='links' style={{ justifyContent: partnerLogo ? 'flex-end' : 'flex-start' }}>
                    {headerLinkItems}
                </div>
            );
        else return (
            <div className='menu-wrapper'>
                <div className='menu-option' onClick={toggleShowLink}>
                    <FaBars />
                </div>
                {isDropdownVisible &&
                    <div className='small-screen-links'>
                        {headerLinkItems}
                    </div>
                }
            </div>
        );
    }

    return (
        <div className='header-data-container container-side-padding'>
            <div className='header-wrapper full-height container-large-width'>
                <div className='logo'>
                    <img src='/images/logos/airlegit-logo-1.png' alt='AirLegit' onClick={() => gotoHome()} />
                </div>

                {!!partnerLogo && innerWidth > 767 &&
                    <div className='partner-logo' style={{ minWidth: partnerLogo.width }}>
                        <img src={partnerLogo.src} alt={partnerLogo.alt} onClick={() => gotoPartnerLink(partnerLogo.link)} />
                    </div>
                }
                {renderNavbarLinks()}
            </div>
        </div>
    );
};

export default Header;